import axios from '@/plugins/axios';

class SmsService {
  getShortCodes(data) {
    this.data = data;
    return axios().get('admin/sms/short-codes', { params: this.data }).then((response) => response.data);
  }

  getShortCode(id) {
    this.id = id;
    return axios().get(`admin/sms/short-codes/${id}`, { params: this.data }).then((response) => response.data);
  }

  updateShortCode(data) {
    this.data = data;
    return axios().put(`admin/sms/short-codes/${data.id}`, this.data).then((response) => response.data);
  }

  getInsights(data) {
    this.data = data;
    return axios().get('admin/sms/insights', { params: this.data }).then((response) => response.data);
  }

  getHistory(data) {
    this.data = data;
    return axios().get('admin/sms/history', { params: this.data }).then((response) => response.data);
  }

  getSales(data) {
    this.data = data;
    return axios().get('admin/sms/sales', { params: this.data }).then((response) => response.data);
  }

  createShortCode(data) {
    this.data = data;
    return axios().post('admin/sms/short-codes', this.data)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  deleteShortCode(id) {
    this.id = id;
    return axios().delete(`admin/sms/short-codes/${id}`).then((response) => response.data);
  }
}

export default new SmsService();
